import styled from 'styled-components';

const HippaIconsStyle = styled.div`
    .commone_icons_text {
        display: flex;
        flex-direction: column;
        align-items: center; 
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        z-index: 1;
        p{
            color: #000;
        }
    }
`;

export default HippaIconsStyle;
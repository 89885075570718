import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import HippaIconsStyle from './HippaIconSection.style';
import { LinkAnchor } from '../CommonMethods';

function HippaIconSection(props) {

    const ICONS_LINK = [
        { icon: "hippa", url: "https://www.hhs.gov/sites/default/files/privacysummary.pdf" },
        { icon: "clia", url: "https://www.cms.gov/Regulations-and-Guidance/Legislation/CLIA" },
        { icon: "cap", url: "https://www.cap.org/laboratory-improvement/accreditation/laboratory-accreditation-program" },
        // { icon: "gma", url: "https://www.nsf.org/testing/health/nutritional-supplements-personal-care-products/gmp-certification" },
        // { icon: "non_gmo", url: "https://www.nsf.org/testing/health/nutritional-supplements-personal-care-products/gmp-certification" },
        // { icon: "gluten_free", url: "https://www.nsf.org/testing/health/nutritional-supplements-personal-care-products/gmp-certification" }
    ];

    return (
        <HippaIconsStyle>
            <div className='commone_icons_text'>
                <div className='fifth_section_icons'>
                    {_.map(ICONS_LINK, ({ icon, url }, index) => (
                        <LinkAnchor key={icon} href={url} target="_blank">
                            <img src={`/images/${icon}.png`} />
                        </LinkAnchor>
                    ))}
                </div>
                <div className='small_text'>
                    <p className='my-3'>Manufactured in an NSF Certified, cGMP Compliant and FDA Registered facility.</p>
                </div>
            </div >
        </HippaIconsStyle >
    )
}

HippaIconSection.propTypes = {}

export default HippaIconSection;
